import React, { useEffect } from 'react';

const Notification = ({ isVisible, isVisible2, classStatus, classStatus2, message, hide }) => {

    useEffect(() => {
       if (isVisible) {
            const timeoutId = setTimeout(() => {
                hide();
            }, 10000);
            return () => clearTimeout(timeoutId);
        }
      }, [isVisible, hide]);

    const addClass = isVisible ? classStatus : '';
    const addClass2 = isVisible2 ? classStatus2 : '';
    
    return (
        <div>
        
            <div className={`div_notification${addClass}`}>
                <div className={`div_notification_content${addClass2}`}>
                    {message}
                    <div><button onClick={hide}>Close</button></div>
                    </div>
                
            </div>
            
        
        </div>
    );
};

export default Notification;