import { useState } from 'react';

export const useNotification = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [message, setMessage] = useState('');
    const [classStatus, setClassStatus] = useState('');
    const [classStatus2, setClassStatus2] = useState('');

    function show(text, classS) {
      setMessage(text);
      setClassStatus(' fade-in notification_show');
      setClassStatus2(' ' + classS);
      setIsVisible(true);
      setIsVisible2(true);
      console.log(text);
      
    };

    function hide() {
      setIsVisible(false);
      console.log('hide');
      setTimeout(() => {
        setMessage('');
        setClassStatus('');
        setClassStatus2('');
        setIsVisible2(false);
      }, 100);
      
    };

    return { isVisible, isVisible2,classStatus, classStatus2, message, show, hide };
};