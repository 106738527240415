import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Notifications from './Notification';
import { useNotification } from './useNotification';

function Rifa() {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [data, setData] = useState([]);
    const [datali, setDataLi] = useState([]);
    const [numeros, setNumeros] = useState([]);
    const [init, setInit] = useState(false);
    const [avenda, setAvenda] = useState(0);
    const [carrega, setCarrega] = useState(false);

    const { isVisible, isVisible2, classStatus, classStatus2, message, show, hide } = useNotification();

    const handleSave = useCallback(async () => {
        try {
          await axios({
            method: 'put',
            url: 'https://rifa-json.belechedev.com/save.php',
            headers: { 'Content-Type': 'application/json' },
            data: { data: data }
          });
        } catch (error) {
          console.error(error);
        }
        console.log('teste');
      }, [data]);

    const getPhpJson = async () => {
        axios.get('https://rifa-json.belechedev.com/rifa.php')
        .then(response => {
            setNumeros(response.data);
            console.log(response.data);
            setCarrega(true);
        })
        .catch(error => {
            console.error(error);
        });
    }

    const contarNumeros = (p) => {
        let av = 0;
        for(let i = 0; i <= (p.length - 1); i++){
            if(p[i].nome === '*'){
                av++;
            }
        }
        setAvenda(av);
    }
      
    const handleSubmit = useCallback(() => {
        if(quantity > avenda){
            show('Tentando cadastrar mais numeros do que é possível!!', 'div_contorno_danger');
            return;
        }
        let num;
        let i = 0;
        let numbers = [];
        let text = '';
        while(i < quantity){
            num = Math.floor(Math.random() * 201);
            let liberado = true;
            console.log(data.length + ' - ' + num);
            for(let ii = 0; ii < data.length; ii++){
                if(data[ii].numero === num && data[ii].nome !== '*'){
                    liberado = false;
                    break;
                }else{

                }
            }
            if(liberado){
                if(num !== 0){
                    i++;
                    numbers.push(num);
                    data[(num - 1)].nome = name;
                    text += ' : ' + num;
                }
                
            }
        }
        console.log(numbers);

        show(name + ' Comprou os números' + text, 'div_contorno_success');
        setData(data);
        setQuantity('');
        setName('');
        handleSave();
        formataNumeros(data);
        contarNumeros(data);
    },[show, avenda, data, handleSave, name, quantity]);

    const montaRifa = useCallback(() => {
        const rifa = [];
        for(let i = 0; i < 200; i++){
            rifa[i] = {
                nome: '*',
                numero: i + 1
            };
        }
        setData(rifa);
        handleSave();
        setInit(true);
        formataNumeros(rifa);
        contarNumeros(rifa);
        
    }, [handleSave]);

    const formataNumeros = (datap) => {
        let lidata = [];
        if(datap.length > 0){
            for(let i = 0; i < 200;i++){
                if(datap[i].nome === '*'){
                    lidata.push(
                        <li className='numeros' key={i}><div className='rifas_numero'><p className='numero'>{datap[i].numero}</p></div><div className='rifas'><p className='nome2'>{datap[i].nome}</p></div></li>
                    );
                }else{
                    lidata.push(
                        <li className='numeros' key={i}><div className='rifas_numero'><p className='numero'>{datap[i].numero}</p></div><div className='rifas'><p className='nome'>{datap[i].nome}</p></div></li>
                    );
                }
                
            }
        }
        setDataLi(lidata);
    }
    useEffect(() => {
        if(Object.keys(numeros).length === 0) {
            if(!carrega){
                getPhpJson();
            }
            if(!init && carrega){
                montaRifa();
            }
            
        }else{
            if(!init){
                setInit(true);
                setData(numeros);
                formataNumeros(numeros);
            }
            
        }
        const interval = setInterval(() => {
            if(data.length > 0){
                contarNumeros(data);
            }
            
        }, 1000);
        /*const interval2 = setInterval(() => {
            if(data.length > 0){
                handleSave();
            }
        }, 30000);*/
        return () => {
            clearInterval(interval);
            //clearInterval(interval2);
        }
        
    }, [data, carrega, init, montaRifa, numeros, hide]);
  
    return (
        <div className='alinhamento'>
            <div className='alinhamento2'>
            <div className='input_div'>
                <p><input value={name} onChange={e => setName(e.target.value)} placeholder="Nome do comprador" /></p>
                <p><input value={quantity} onChange={e => setQuantity(e.target.value)} placeholder="Quantidade de numeros" /></p>
                <p><button onClick={handleSubmit}>Cadastrar</button></p>
                <p className='avenda_p'>Números a venda: {avenda}</p>
            </div>
            </div>
            <Notifications  isVisible={isVisible} isVisible2={isVisible2} classStatus={classStatus} classStatus2={classStatus2} message={message} hide={hide} />
            <div className='principal'>
                <ul>
                    {datali}
                </ul>
            </div>
      </div>
    );
  }
  
  export default Rifa;